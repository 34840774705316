import { CircularProgress, IconButton, Snackbar } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface DashingSnackbarProps {
  open: boolean;
  variant: string;
  message: string;
  handleClose: (args: any) => void;
}

export const DashingSnackbar = ({ open, variant, message, handleClose }: DashingSnackbarProps) => {
  const action = (
    <React.Fragment>
      {variant === 'loading' ? (
        <CircularProgress size="20px" style={{ color: '#ffffff' }} />
      ) : (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => handleClose({ open: false })}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </React.Fragment>
  );
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      ContentProps={{
        sx: {
          background: variant === 'error' ? '#d32f2f' : '#0288d1',
        },
      }}
      message={message}
      action={action}
    />
  );
};
