import { ThemeProvider } from '@mui/system';
import { QxMetricsTable } from './containers/QxMetricsTable/QxMetricsTable.index';
import { theme } from './theme/minimal';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QxMetricsTable />
    </ThemeProvider>
  );
}

export default App;
