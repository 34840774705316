export const columnGroups = [
  {
    id: 'questionnaire_details',
    name: 'Questionnaire content',
    columns: [
      { id: 'number_of_lines', name: 'Lines' },
      { id: 'size_in_kb', name: 'Size in kb' },
      { id: 'elements', name: 'Elements' },
      { id: 'pages', name: 'Pages' },
      { id: 'questions', name: 'Questions' },
      { id: 'interlude_lines', name: 'Interlude lines' },
      { id: 'interludes_count', name: 'Interlude count' },
      { id: 'extra_css', name: 'Extra css' },
      { id: 'extra_js', name: 'Extra js' },
      { id: 'widget_count', name: 'Widget count' },
      { id: 'long_text', name: 'Long text' },
      { id: 'has_qc_mapping', name: 'Has QC Mapping' },
    ],
  },
  {
    id: 'interlude_content',
    name: 'Interlude content',
    columns: [
      { id: 'resource_calls', name: 'Resource calls' },
      { id: 'panman_calls', name: 'Panman calls' },
      { id: 'csv_calls', name: 'CSV calls' },
      { id: 'history_calls', name: 'History calls' },
      { id: 'panman_assign_treatment_count', name: 'Assign treatments' },
    ],
  },
  {
    id: 'pdl_content',
    name: 'Pdl content',
    columns: [
      { id: 'pdl_updates', name: 'Pdl Updates' },
      { id: 'pdl_updates_guarded', name: 'Pdl updates guarded' },
      { id: 'pdl_updates_unguarded', name: 'Pdl updates unguarded' },
      { id: 'pdl_updates_if_0', name: 'Pdl updates if 0' },
      { id: 'pdl_sets', name: 'Pdl sets' },
    ],
  },
  {
    id: 'exit_counts',
    name: 'Exit counts',
    columns: [
      { id: 'exit_counts_by_status', name: 'By status' },
      { id: 'exit_counts_by_option', name: 'By option' },
    ],
  },
  {
    id: 'gotos',
    name: 'Gotos',
    columns: [
      { id: 'surveys', name: 'Surveys' },
      { id: 'sites', name: 'Sites' },
      { id: 'modules', name: 'Modules' },
      { id: 'pages', name: 'Pages' },
    ],
  },
  {
    id: 'responses_activity',
    name: 'Response Options',
    columns: [
      { id: 'responses_all', name: 'Response all' },
      { id: 'responses_50_99', name: 'Response 50 99' },
      { id: 'responses_100_499', name: 'Response 100 499' },
      { id: 'responses_500_999', name: 'Response 500 999' },
      { id: 'responses_1000_plus', name: 'Response 1000 plus' },
      { id: 'named_response_list_count', name: 'Named Response List Count' },
      { id: 'object_sets', name: 'Object Sets Count' },
    ],
  },
  {
    id: 'subquestions',
    name: 'Subquestions',
    columns: [
      { id: 'subquestions_all', name: 'Subquestions all' },
      { id: 'subquestions_50_99', name: 'Subquestions 50 99' },
      { id: 'subquestions_100_499', name: 'Subquestions 100 499' },
      { id: 'subquestions_500_999', name: 'Subquestions 500 999' },
      { id: 'subquestions_1000_plus', name: 'Subquestions 1000 plus' },
    ],
  },
  {
    id: 'guards',
    name: 'Guards',
    columns: [
      { id: 'page_module_question_guards', name: 'Page module question guards' },
      { id: 'subquestion_response_guards', name: 'Subquestion guards' },
      { id: 'response_guards', name: 'Response guards' },
    ],
  },
  {
    id: 'languages',
    name: 'Languages',
    columns: [
      { id: 'authored_language', name: 'Authored language' },
      { id: 'used_locales', name: 'Locales' },
      { id: 'authored_language_overriden', name: 'Authored language overriden' },
      { id: 'locales_metadata', name: 'Locales metadata' },
      { id: 'detected_qsl_languages', name: 'Detected qsl languages' },
      { id: 'authored_language_match_qsl_language', name: 'Authored language match qsl language' },
      { id: 'mixed_qsl_languages', name: 'Mixed qsl languages' },
    ],
  },
];
