export type WidgetCount = {
  [key: string]: number;
};

export type DetectedQsl = {
  [key: string]: number;
};

interface QxDetails extends QxDetailsCommon {
  widget_count: WidgetCount[];
  last_started: string;
}

interface QxDetailsCommon {
  questionnaire_name: string;
  version: number;
  active_version: number;
  number_of_lines: number;
  size_in_kb: number;
  interlude_lines: number;
  interlude_count: number;
  elements: number;
  pages: number;
  questions: number;
  panel: number;
  extra_css: boolean;
  extra_js: boolean;
}

interface QxDetailsAPI extends QxDetailsCommon, Languages {
  panman_assign_treatment_count: number;
  widget_count: Record<string, number>;
  exit_counts: object;
  named_response_list_count: number;
  csv_calls: number;
  long_text: QxLongText;
  object_sets: number;
  last_started: string;
}

interface Languages {
  authored_language: string[];
  used_locales: string[];
  authored_language_match_qsl_language: boolean;
  authored_language_overriden: boolean;
  locales_metadata: object;
  mixed_qsl_languages: boolean;
  detected_qsl_languages: DetectedQsl;
}

interface QxExitCounts {
  exit_counts_by_status: object;
  exit_counts_by_option: object;
}

interface QxGotos {
  surveys: number;
  sites: number;
  modules: number;
  pages: number;
}

interface QxResponsesActivity {
  responses_50_99: number;
  responses_100_499: number;
  responses_500_999: number;
  responses_1000_plus: number;
  responses_all: number;
}

interface QxCommitDetails {
  committer: string;
  commit_datetime: string;
}

interface QxInterludeContent {
  resource_calls: number;
  panman_calls: number;
  csv_calls: number;
  history_calls: number;
  panman_assign_treatment_count: number;
}

interface QxPdlContent {
  pdl_updates: number;
  pdl_updates_guarded: number;
  pdl_updates_unguarded: number;
  pdl_updates_if_0: number;
  pdl_sets: number;
}

interface QxCallActivityApi {
  history_calls: number;
  resource_calls: number;
  panman_calls: number;
  pdl_updates: number;
  pdl_updates_guarded: number;
  pdl_updates_unguarded: number;
  pdl_updates_if_0: number;
  pdl_sets: number;
}

interface QxSubquestions {
  subquestions_50_99: number;
  subquestions_100_499: number;
  subquestions_500_999: number;
  subquestions_1000_plus: number;
  subquestions_all: number;
}

interface QxGuards {
  page_module_question_guards: number;
  subquestion_response_guards: number;
  response_guards: number;
}

interface QxLongText {
  text_20_50: number;
  text_50_100: number;
  text_100_plus: number;
}

export interface QxMetricApi extends Record<string, object | string> {
  admin_url: string;
  questionnaire_details: QxDetailsAPI;
  gotos: QxGotos;
  responses_activity: QxResponsesActivity;
  commit_details: QxCommitDetails;
  call_activity: QxCallActivityApi;
  subquestions: QxSubquestions;
  guards: QxGuards;
}

export interface QxMetric extends Record<string, object | string> {
  admin_url: string;
  questionnaire_details: QxDetails;
  interlude_content: QxInterludeContent;
  pdl_content: QxPdlContent;
  exit_counts: QxExitCounts;
  gotos: QxGotos;
  responses_activity: QxResponsesActivity;
  commit_details: QxCommitDetails;
  subquestions: QxSubquestions;
  guards: QxGuards;
  languages: Languages;
  // @ts-ignore
  errorRow: boolean;
}

export interface QxMetricsAPIResponse {
  qxvs: QxMetricApi[];
  total: number;
}
export interface QxMetricsResponse {
  qxvs: QxMetric[];
  total: number;
}
export interface QxMetricsQuery {
  [key: string]: unknown;
  limit?: number;
  page?: number;
  panel?: string[];
  authored_language?: string;
  used_locales?: string[];
  sort_by?: string;
  sort_dir?: string;
  questionnaire_name?: string;
  committer_name?: string;
}

export interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  sortDir: SortDir;
  sortBy: string;
  columnGroups: any;
  stickyColumnStyle: any;
}

export enum SortDir {
  ASC = 'asc',
  DESC = 'desc',
}

export enum Dates {
  LAST_WEEK = '7d',
  LAST_24H = '1d',
  LAST_2W = '14d',
  LAST_MONTH = '1m',
}

export interface QxSearchState {
  searchTexts: { questionnaireName?: string; committerName?: string; content?: string };
}
