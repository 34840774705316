import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useGetQxMetricsQuery } from '../../services/dashing-api';
import { RootState } from '../../state';
import { SortDir } from '../../types';
import { Columns } from '../Columns/Columns.index';
import { Regions } from '../Regions/Regions.index';
import { columnGroups } from '../Columns/ColumnsGroups';
import { EnhancedTableHead } from '../EnhancedTableHead/EnhancedTableHead.index';
import { Filters } from '../Filters/Filters.index';
import styles from './QxMetricsTable.module.css';
import { Search } from '../Search/Search.index';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';
import { setPage, setSorting } from '../../state/qxTableSlice';
import { useDispatch } from 'react-redux';
import { useQuerySearch } from '../../hooks/useQuerySearch';
import { useGetDragomanLanguagesQuery } from '../../services/dragoman-api';
import { useRef, useState } from 'react';
import { hashedData } from '../../util';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useGetISurveyUrlMutation, useGetTestLinkMutation } from '../../services/gryphon-api';
import React from 'react';
import { DashingSnackbar } from '../../components/DashingSnackbar/DashingSnackbar.index';
import QCMetrics from '../../components/QCMetrics/QCMetrics.index';

const { REACT_APP_GRYPHON_API_URL, REACT_APP_LOGIN_URL } = process.env;

export const QxMetricsTable = () => {
  const dispatch = useDispatch();
  const tableRef = useRef<null | HTMLTableElement>(null);

  // Columns visibility
  const columnsVisible = useSelector((state: RootState) => state.qxTable.visibilityColumns);

  // Filters
  const panels = useSelector((state: RootState) => state.qxTable.panels);
  const authoredLanguage = useSelector((state: RootState) => state.qxTable.authoredLanguage);
  const usedLocales = useSelector((state: RootState) => state.qxTable.usedLocales);
  const commitDatesRange = useSelector((state: RootState) => state.qxTable.commitDatesRange);
  const { sortBy, sortDir } = useSelector((state: RootState) => state.qxTable.sorting);
  const widgets = useSelector((state: RootState) => state.qxTable.widgets);
  const liveCases = useSelector((state: RootState) => state.qxTable.liveCases);

  // Pagination
  const page = useSelector((state: RootState) => state.qxTable.page);

  // Search terms
  const searchTexts = useSelector((state: RootState) => state.qxTable.searchTexts);

  const dragomanLanguagesResult = useGetDragomanLanguagesQuery();
  const [dashingSnackbarConfig, setDashingSnackbarConfig] = useState<Record<string, unknown>>({
    open: false,
  });
  const { queryParameters } = useQuerySearch({
    page,
    panels,
    authoredLanguage,
    usedLocales,
    commitDatesRange,
    sortBy,
    sortDir,
    searchTexts,
    columnsVisible,
    widgets,
    liveCases,
  });

  const { data, isFetching, isLoading } = useGetQxMetricsQuery(queryParameters);
  const [getTestLink] = useGetTestLinkMutation();
  const [getISurveyUrl] = useGetISurveyUrlMutation();
  if (isLoading) return <div>Loading...</div>;
  if (!data) return null;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = sortBy === property && sortDir !== SortDir.ASC;
    dispatch(
      setSorting({
        sortBy: property,
        sortDir: isAsc ? SortDir.ASC : SortDir.DESC,
      })
    );
  };

  const testSurvey = (row: any) => (event: React.MouseEvent<unknown>) => {
    setDashingSnackbarConfig({ open: true, variant: 'loading', message: 'Opening Test Survey...' });
    const surveyName = row.questionnaire_details.questionnaire_name;
    getTestLink({ surveyName })
      .unwrap()
      .then((response: any) => {
        if (response.url) {
          getISurveyUrl(response)
            .unwrap()
            .then((ISurvey: any) => {
              setDashingSnackbarConfig({ open: false });
              window.open(ISurvey?.url || response.url, '_blank', 'noopener,noreferrer');
            })
            .catch((error) => {
              setDashingSnackbarConfig({ open: false });
              window.open(response.url, '_blank', 'noopener,noreferrer');
            });
        } else {
          setDashingSnackbarConfig({
            open: true,
            variant: 'error',
            message: 'Something went wrong! Try again!',
          });
        }
      })
      .catch((error) => {
        if (error?.originalStatus === 401) {
          setDashingSnackbarConfig({
            open: true,
            variant: 'error',
            message: 'Please login to Gryphon again!',
          });
          window.open(
            `${REACT_APP_LOGIN_URL}/?redirect_to=${REACT_APP_GRYPHON_API_URL}`,
            '_blank',
            'noopener,noreferrer'
          );
        } else {
          setDashingSnackbarConfig({
            open: true,
            variant: 'error',
            message: 'Something went wrong! Try again!',
          });
        }
      });
  };

  return (
    <div className={styles.wrapper}>
      <HeaderWrapper direction="row" spacing={2} justifyContent="space-between">
        <Search searchTexts={searchTexts} />
        <Columns columnsVisible={columnsVisible} />
      </HeaderWrapper>
      <Filters
        panels={panels}
        authoredLanguage={authoredLanguage}
        usedLocales={usedLocales}
        commitDatesRange={commitDatesRange}
        widgets={widgets}
        liveCases={liveCases}
      />
      {isFetching && <LinearProgress />}
      <div className={styles.tableWrap}>
        <TableContainer sx={{ height: '100%' }} component={Paper}>
          <Table size="small" stickyHeader ref={tableRef}>
            <EnhancedTableHead
              sortDir={sortDir}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
              columnGroups={columnGroups}
              stickyColumnStyle={stickyColumnStyle}
            />
            <TableBody>
              {data.qxvs.map((row) => (
                <TableRow
                  key={row.admin_url}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:nth-of-type(odd) th, &:nth-of-type(odd) td': {
                      backgroundColor: 'rgb(245 245 245)',
                    },
                  }}
                  className={row.errorRow ? styles.rowError : ''}>
                  <TableCell key="qxName" component="th" scope="row" sx={stickyColumnStyle}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <QxLink href={row.admin_url} target="_blank" rel="noreferrer">
                        {row.questionnaire_details.questionnaire_name}
                      </QxLink>
                      <Tooltip
                        title={
                          row.questionnaire_details.active_version
                            ? 'Test Survey'
                            : 'No Active Version'
                        }>
                        <div>
                          <IconButton
                            aria-label="Test Survey"
                            color="primary"
                            onClick={testSurvey(row)}
                            size="small"
                            sx={{ marginLeft: '10px' }}
                            disabled={!row.questionnaire_details.active_version}>
                            <OpenInNewIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell key="qxVersion" component="th" scope="row">
                    {row.questionnaire_details.version}
                  </TableCell>
                  <TableCell key="qxPanel" component="th" scope="row">
                    {row.questionnaire_details.panel}
                  </TableCell>
                  <TableCell key="qxCommiter" component="th" scope="row">
                    {row.commit_details.committer}
                  </TableCell>
                  <TableCell key="qxDatetime" component="th" scope="row">
                    {formatDateTime(row.commit_details.commit_datetime)}
                  </TableCell>
                  <TableCell key="qxDatetime" component="th" scope="row">
                    {formatDateTime(row.questionnaire_details.last_started)}
                  </TableCell>
                  {columnGroups.flatMap((colGroup) => {
                    if (columnsVisible.includes(colGroup.id)) {
                      const columnKeys = colGroup.columns;
                      return columnKeys.map((columnKey, index) => {
                        const column = row[colGroup.id] as Record<
                          string,
                          string | number | string[] | number[]
                        >;
                        return (
                          <TableCell
                            key={row.admin_url + colGroup.id + columnKey.id}
                            className={index === 0 ? styles.tableCellLeftBorder : ''}>
                            {renderValue(columnKey.id, column[columnKey.id], {
                              data: dragomanLanguagesResult.data,
                              isLoading: dragomanLanguagesResult.isLoading,
                              isError: dragomanLanguagesResult.isError,
                            })}
                          </TableCell>
                        );
                      });
                    }
                    return null;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={styles.tableFooter}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
          <Stack direction="row" columnGap={1}>
            <Regions queryParams={queryParameters} />
            <QCMetrics />
          </Stack>

          <TablePagination
            showFirstButton
            rowsPerPageOptions={[10, 25, 50]}
            colSpan={3}
            count={data.total}
            rowsPerPage={page.limit}
            page={page.page}
            onPageChange={(e, pageNumber) => {
              tableRef.current && tableRef!.current!.scrollIntoView();
              dispatch(setPage({ limit: page.limit, page: pageNumber }));
            }}
            onRowsPerPageChange={(e) =>
              dispatch(setPage({ limit: parseInt(e.target.value), page: page.page }))
            }
            ActionsComponent={TablePaginationActions}
          />
        </Stack>
      </div>
      <DashingSnackbar
        open={dashingSnackbarConfig.open as boolean}
        variant={dashingSnackbarConfig.variant as string}
        message={dashingSnackbarConfig.message as string}
        handleClose={setDashingSnackbarConfig}
      />
    </div>
  );
};

const formatDateTime = (value: string | string[]) => {
  return !value ? (
    <></>
  ) : (
    <div className={styles.dateTimeContainer}>
      <div className={styles.dateSection}>
        {format(new Date((Array.isArray(value) ? [value] : value) as string), 'MMM d, yyyy')}
      </div>
      <div className={styles.timeSection}>
        {format(new Date((Array.isArray(value) ? [value] : value) as string), 'H:mm a')}
      </div>
    </div>
  );
};

const renderValue = (
  columnId: string,
  value: string | number | string[] | number[] | boolean,
  dragomanLanguages?: any
) => {
  switch (columnId) {
    case 'detected_qsl_languages':
      if (dragomanLanguages.isLoading) {
        return 'Loading...';
      } else {
        return Object.entries(value)
          .flatMap(([index, obj]) => {
            return `${
              dragomanLanguages.isError ||
              (dragomanLanguages.data as any)[Object.keys(obj)[0]] === undefined
                ? Object.keys(obj)[0]
                : (dragomanLanguages.data as any)[Object.keys(obj)[0]]
            } [${Object.keys(obj)[0]}]: ${obj[Object.keys(obj)[0]]}%`;
          })
          .join(', ');
      }

    case 'authored_language':
    case 'used_locales':
      if (dragomanLanguages.isLoading) {
        return 'Loading...';
      } else {
        return ((Array.isArray(value) ? value : [value]) as string[])
          .map(
            (code) =>
              `${
                dragomanLanguages.isError || (dragomanLanguages.data as any)[code] === undefined
                  ? code
                  : (dragomanLanguages.data as any)[code]
              } [${code}]`
          )
          .join(', ');
      }

    case 'subquestions_500_999':
    case 'subquestions_1000_plus':
      return value > 0 ? <span className={styles.dataError}>{value}</span> : value;

    case 'responses_500_999':
    case 'responses_1000_plus':
    case 'subquestions_100_499':
      return value > 1 ? <span className={styles.dataError}>{value}</span> : value;

    case 'responses_100_499':
      return value > 3 ? <span className={styles.dataError}>{value}</span> : value;

    case 'subquestions_50_99':
      return value > 5 ? <span className={styles.dataError}>{value}</span> : value;

    case 'widget_count':
      return ((Array.isArray(value) ? value : [value]) as string[]).map((widget, index) => (
        <span className={styles.objectRow} key={hashedData(JSON.stringify(value) + index)}>
          <span className={styles.objectRowValue}>{Object.keys(widget).pop()}</span>
          <span className={styles.objectRowSymbol}>:</span>
          <span className={styles.objectRowValue}>{Object.values(widget).pop()}</span>
        </span>
      ));

    case 'long_text':
    case 'exit_counts_by_status':
    case 'exit_counts_by_option':
      if (typeof value === 'object') {
        return Object.keys(value).map((item, index) => (
          <span className={styles.objectRow} key={hashedData(JSON.stringify(item) + index)}>
            <span className={styles.objectRowValue}>{item}</span>
            <span className={styles.objectRowSymbol}>:</span>
            <span className={styles.objectRowValue}>{(value as any)[item]}</span>
          </span>
        ));
      } else {
        return value;
      }
    default:
      if (Array.isArray(value)) {
        return value
          .map((v) =>
            JSON.stringify(v).replace(/[{}]/g, '').replace(/['"]+/g, '').replace(/,/g, ', ')
          )
          .join(', ');
      } else if (typeof value === 'boolean' || typeof value === 'object') {
        //regex will replace quotes and curly brackets with blank space
        return JSON.stringify(value)
          .replace(/[{}]/g, '')
          .replace(/['"]+/g, '')
          .replace(/,/g, ', ')
          .replace(/:/g, ': ');
      } else {
        return value;
      }
  }
};

const stickyColumnStyle = {
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
};

const HeaderWrapper = styled(Stack)`
  margin: 20px 20px 0;
`;

const QxLink = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
