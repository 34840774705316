import styled from '@emotion/styled';
import { Button, Paper, Stack } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state';
import { closeFilters, setFilterOpen } from '../../state/qxTableSlice';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface FilterApplyProps {
  [key: string]: unknown;
  label: string;
  initialValue?: any;
  value: any;
  onApply: ActionCreatorWithPayload<any>;
  renderFormItem: (...props: any) => ReactNode;
}

export const FilterApply = (props: FilterApplyProps) => {
  const { renderFormItem, ...formComponentProps } = props;
  const { label, value, initialValue, onApply } = props;

  const dispatch = useDispatch();

  const currentlyOpenFilter = useSelector((state: RootState) => state.qxTable.currentlyOpenFilter);
  const open = label === currentlyOpenFilter;

  const [selected, setSelected] = useState<string[] | null>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const toggleOpenFilter = () => {
    if (open) {
      dispatch(closeFilters());
    } else {
      dispatch(setFilterOpen(label));
    }
  };

  const handleReset = () => {
    dispatch(closeFilters());
    setSelected(initialValue);
    dispatch(onApply(initialValue));
  };

  const handleApply = () => {
    dispatch(closeFilters());
    dispatch(onApply(selected));
  };

  const hasElementsSelected = () => {
    if (Array.isArray(value) && value.length > 0) return true;
    return Object.values(value).filter((t) => !!t).length > 0;
  };

  const handleClickAway = () => {
    dispatch(closeFilters());
  };

  return (
    <Wrap>
      <Button
        onClick={toggleOpenFilter}
        variant={hasElementsSelected() ? 'contained' : 'outlined'}
        size="small">
        {label} {hasElementsSelected() && Array.isArray(value) && `(${value.length})`}
      </Button>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <FloatingPaper>
            {renderFormItem({ ...formComponentProps, setSelected, selected })}
            <ButtonsWrap direction="row" spacing={1} justifyContent="flex-end">
              <Button variant="outlined" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="contained" onClick={handleApply}>
                Apply
              </Button>
            </ButtonsWrap>
          </FloatingPaper>
        </ClickAwayListener>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
`;

const ButtonsWrap = styled(Stack)`
  padding: 15px;
`;

const FloatingPaper = styled(Paper)`
  position: absolute;
  top: 110%;
  width: 220px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  z-index: 500;
  border: 1px solid rgba(80, 72, 229, 0.5);
  overflow: hidden;
`;
