import { configureStore } from '@reduxjs/toolkit';
import { dashingApi } from '../services/dashing-api';
import { panopticApi } from '../services/panoptic-api';

import qxTableSlice from './qxTableSlice';
import { dragomanApi } from '../services/dragoman-api';
import { gryphonApi } from '../services/gryphon-api';

export const store = configureStore({
  reducer: {
    [dashingApi.reducerPath]: dashingApi.reducer,
    [panopticApi.reducerPath]: panopticApi.reducer,
    [dragomanApi.reducerPath]: dragomanApi.reducer,
    [gryphonApi.reducerPath]: gryphonApi.reducer,
    qxTable: qxTableSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      dashingApi.middleware,
      panopticApi.middleware,
      dragomanApi.middleware,
      gryphonApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
