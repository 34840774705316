import { Button } from '@mui/material';

function QCMetrics() {
  const { REACT_APP_ENVIRONMENT } = process.env;

  const path =
    REACT_APP_ENVIRONMENT === 'production' || REACT_APP_ENVIRONMENT === 'staging'
      ? `QcMetrics/${REACT_APP_ENVIRONMENT}.html`
      : '#';

  return (
    <Button href={path} variant="outlined" size="small" target="_blank">
      QC Metrics
    </Button>
  );
}

export default QCMetrics;
