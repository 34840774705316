import { Button } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

interface ToggleFilterProps {
  label: string;
  value: 0 | 1;
  onApply: ActionCreatorWithPayload<0 | 1>;
}

export function ToggleFilter({ label, value, onApply }: ToggleFilterProps) {
  const dispatch = useDispatch();

  const handleToggle = () => {
    const toggledValue = value === 0 ? 1 : 0;
    dispatch(onApply(toggledValue));
  };

  return (
    <Button size="small" variant={value ? 'contained' : 'outlined'} onClick={handleToggle}>
      {label}
    </Button>
  );
}
