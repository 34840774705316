import { format, subDays } from 'date-fns';
import { isNil } from 'ramda';
import { Dates } from '../types';

export const appDateFormat = 'yyyy-MM-dd';

export const isObject = (value: any) =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const cleanUpParams = (
  params: Record<string, unknown>,
  isUrlParam: boolean
): Record<string, unknown> =>
  Object.keys(params).reduce((acc: any, paramKey) => {
    const value = params[paramKey];
    if (isNil(value)) {
      return acc;
    }

    if (acc.liveCases && !isUrlParam) {
      acc.last_started_from = calculateDateRange(Dates.LAST_24H);
      acc.last_started_to = calculateDateRange();
    }
    if (acc.commitDatesRange && !isUrlParam) {
      acc.start_date = calculateDateRange(acc.commitDatesRange);
      acc.end_date = calculateDateRange();
    }
    return {
      ...acc,
      [paramKey]: isObject(value)
        ? cleanUpParams(value as Record<string, unknown>, isUrlParam)
        : value,
    };
  }, {});

export const calculateDateRange = (duration?: string) => {
  let days = null;
  switch (duration) {
    case Dates.LAST_WEEK:
      days = 7;
      break;
    case Dates.LAST_24H:
      days = 1;
      break;
    case Dates.LAST_2W:
      days = 14;
      break;
    case Dates.LAST_MONTH:
      days = 30;
      break;
    default:
      days = 0;
      break;
  }
  return format(subDays(new Date(), days), appDateFormat);
};

export const hashedData = (data: string): number => {
  if (data.length === 0) return 0;
  let hash = 0;
  for (let i = 0; i < data.length; i++) {
    let ch = data.charCodeAt(i);
    hash = (hash << 5) - hash + ch;
    hash = hash & hash;
  }
  return hash;
};
