import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchTexts } from '../../state/qxTableSlice';
import { QxSearchState } from '../../types';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';

export const Search = ({ searchTexts }: QxSearchState) => {
  const textFieldRefs: any = { 0: useRef(), 1: useRef() };
  const dispatch = useDispatch();
  const [questionnaireName, setQuestionnaireName] = useState(searchTexts.questionnaireName);
  const [committerName, setCommitterName] = useState(searchTexts.committerName);

  const removeFocusFromTextFields = () => {
    Object.keys(textFieldRefs).forEach((key: any) => {
      textFieldRefs[key].current.blur();
    });
  };

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    removeFocusFromTextFields();
    dispatch(
      setSearchTexts({
        questionnaireName: questionnaireName,
        committerName: committerName,
      })
    );
  };
  return (
    <form onSubmit={handleSearch}>
      <Stack direction="row" spacing={2}>
        <TextBox
          inputRef={textFieldRefs[0]}
          label="Questionnaire name"
          placeholder="Enter questionnaire name"
          variant="outlined"
          size="small"
          value={questionnaireName}
          onChange={(e) => setQuestionnaireName(e.target.value)}
        />
        <TextBox
          inputRef={textFieldRefs[1]}
          label="Committer name"
          placeholder="Enter commiter name"
          variant="outlined"
          size="small"
          value={committerName}
          onChange={(e) => setCommitterName(e.target.value)}
        />
        <Button type="submit" variant="contained" startIcon={<SearchIcon />}>
          Search
        </Button>
      </Stack>
    </form>
  );
};

const TextBox = styled(TextField)`
  flex: 1;
`;
