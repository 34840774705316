import styled from '@emotion/styled';
import {
  Checkbox,
  FormControl,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@mui/material';
import { useState } from 'react';
import styles from './FilterSelect.module.css';

interface FilterOption {
  value: string;
  label: string;
}

interface FilterSelectProps {
  selected: string[] | null;
  setSelected: (value: string[]) => void;
  options: FilterOption[];
  multiple?: boolean;
}

export const FilterSelect = ({ selected, setSelected, options, multiple }: FilterSelectProps) => {
  const TickComponent = multiple ? Checkbox : Radio;

  const [localFilter, setLocalFilter] = useState<string>();

  const handleToggle = (value: string) => () => {
    if (!multiple || !selected) {
      return setSelected([value]);
    }

    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelected(newChecked);
  };

  const selectAllEvent = () => () => {
    if (!!selected && options.length === selected.length) {
      setSelected([]);
    } else {
      const newChecked = options.map((option: any) => {
        return option.value;
      });
      setSelected(newChecked);
    }
  };

  const renderSelectAll = () => {
    return (
      <ListItem key="false" value="false" disablePadding>
        <ListItemButton role={undefined} onClick={selectAllEvent()} dense>
          <ListItemIcon>
            <Checkbox
              checked={!!selected && selected.length === options.length}
              indeterminate={!!selected && selected.length > 0 && selected.length < options.length}
              size="small"
            />
          </ListItemIcon>
          <ListItemText className={styles.listItemText} primary={'Select all'} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <FormControl sx={{ minWidth: 220, flex: 1, overflow: 'scroll' }} size="small">
      <List>
        <FormControl sx={{ minWidth: 220 }}>
          <IndentedInput
            placeholder="Filter..."
            value={localFilter}
            onChange={(e) => setLocalFilter(e.target.value)}
          />
        </FormControl>
        {multiple ? renderSelectAll() : ''}
        {options
          .filter((option) => !localFilter || option.label.toLowerCase().includes(localFilter))
          .map((option) => (
            <ListItem key={option.value} value={option.value} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(option.value)} dense>
                <ListItemIcon>
                  <TickComponent checked={selected?.includes(option.value) || false} size="small" />
                </ListItemIcon>
                <ListItemText primary={option.label} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </FormControl>
  );
};

const IndentedInput = styled(Input)`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
`;
