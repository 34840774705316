//TODO: Remove this when we get the dynamic list
export const widgetOptions = [
  { label: 'single', value: 'single' },
  { label: 'multiple', value: 'multiple' },
  { label: 'open', value: 'open' },
  { label: 'grid', value: 'grid' },
  { label: 'scale', value: 'scale' },
  { label: 'open-intrange', value: 'open-intrange' },
  { label: 'dropdown', value: 'dropdown' },
  { label: 'rule', value: 'rule' },
  { label: 'single-colorpicker', value: 'single-colorpicker' },
  { label: 'grid-check', value: 'grid-check' },
  { label: 'multiple-colorpicker', value: 'multiple-colorpicker' },
  { label: 'dyngrid', value: 'dyngrid' },
  { label: 'open-int', value: 'open-int' },
  { label: 'video', value: 'video' },
  { label: 'colorpicker', value: 'colorpicker' },
  { label: 'rank', value: 'rank' },
  { label: 'grid-open', value: 'grid-open' },
  { label: 'card-sort', value: 'card-sort' },
  { label: 'open-realrange', value: 'open-realrange' },
  { label: 'placement', value: 'placement' },
  { label: 'dyngrid-check', value: 'dyngrid-check' },
  { label: 'language', value: 'language' },
  { label: 'open-real', value: 'open-real' },
  { label: 'state', value: 'state' },
  { label: 'autoadvance', value: 'autoadvance' },
  { label: 'email', value: 'email' },
  { label: 'video-dial', value: 'video-dial' },
  { label: 'thermometer', value: 'thermometer' },
  { label: 'audio', value: 'audio' },
  { label: 'imagemap-multiple', value: 'imagemap-multiple' },
  { label: 'zipcode', value: 'zipcode' },
  { label: 'address', value: 'address' },
  { label: 'reg-address', value: 'reg-address' },
  { label: 'text-highlight', value: 'text-highlight' },
  { label: 'image-highlight', value: 'image-highlight' },
  { label: 'calendar', value: 'calendar' },
  { label: 'facebook-login', value: 'facebook-login' },
  { label: 'reg-name', value: 'reg-name' },
  { label: 'imagemap-single', value: 'imagemap-single' },
  { label: 'upload', value: 'upload' },
  { label: 'reg-global', value: 'reg-global' },
  { label: 'country', value: 'country' },
  { label: 'popup', value: 'popup' },
  { label: 'registration', value: 'registration' },
];
