import { Button, Stack } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { FilterApply } from '../../components/FilterApply/FilterApply.index';
import { FilterSelect } from '../../components/FilterSelect/FilterSelect.index';
import {
  resetFilters,
  setAuthoredLanguage,
  setCommitDates,
  setPanels,
  setUsedLocales,
  setWidgets,
  setLiveCases,
} from '../../state/qxTableSlice';
import styles from './Filters.module.css';
import { useDispatch } from 'react-redux';
import { useGetPanelsQuery } from '../../services/panoptic-api';
import { useGetGryphonLocalesQuery } from '../../services/dragoman-api';
import { DragomanLanguage } from '../../types/dragoman';
import { Dates } from '../../types';
import { widgetOptions } from '../../mocks/widgets';
import { ToggleFilter } from '../../components/ToggleFilter/ToggleFilter.index';

interface FiltersProps {
  panels: string[];
  authoredLanguage: string[];
  usedLocales: string[];
  commitDatesRange: string;
  widgets: string[];
  liveCases: 0 | 1;
}

export const Filters = ({
  panels,
  authoredLanguage,
  usedLocales,
  commitDatesRange,
  widgets,
  liveCases,
}: FiltersProps) => {
  const dispatch = useDispatch();
  const panopticResult = useGetPanelsQuery();
  const gryphonLocalesResult = useGetGryphonLocalesQuery();

  const dateRangeOptions = [
    { name: 'Last 24 Hr', id: Dates.LAST_24H },
    { name: 'Last Week', id: Dates.LAST_WEEK },
    { name: 'Last 2 Weeks', id: Dates.LAST_2W },
    { name: 'Last Month', id: Dates.LAST_MONTH },
  ];

  return (
    <Stack direction="row" justifyContent="space-between" className={styles.filtersWrap}>
      <Stack direction="row" spacing={1}>
        {panopticResult.data ? (
          <FilterApply
            label="Panel"
            value={panels}
            initialValue={[]}
            onApply={setPanels}
            options={mapIdNamePairsToOptions(panopticResult.data.panels)}
            multiple
            renderFormItem={(props) => <FilterSelect {...props} />}
          />
        ) : (
          <Button disabled variant="outlined" size="small">
            {panopticResult.error ? 'Error' : 'Loading...'}
          </Button>
        )}
        {gryphonLocalesResult.data ? (
          <FilterApply
            label="Authored language"
            value={authoredLanguage}
            initialValue={[]}
            onApply={setAuthoredLanguage}
            options={mapNameCodePairsToOptions(gryphonLocalesResult.data.languages)}
            multiple
            renderFormItem={(props) => <FilterSelect {...props} />}
          />
        ) : (
          <Button disabled variant="outlined" size="small">
            {gryphonLocalesResult.error ? 'Error' : 'Loading...'}
          </Button>
        )}
        {gryphonLocalesResult.data ? (
          <FilterApply
            label="Locales"
            value={usedLocales}
            initialValue={[]}
            onApply={setUsedLocales}
            options={mapNameCodePairsToOptions(gryphonLocalesResult.data.languages)}
            multiple
            renderFormItem={(props) => <FilterSelect {...props} />}
          />
        ) : (
          <Button disabled variant="outlined" size="small">
            {gryphonLocalesResult.error ? 'Error' : 'Loading...'}
          </Button>
        )}
        <FilterApply
          label="Commit Dates"
          value={commitDatesRange}
          initialValue={['']}
          onApply={setCommitDates}
          options={mapIdNamePairsToOptions(dateRangeOptions)}
          renderFormItem={(props) => <FilterSelect {...props} />}
        />
        <FilterApply
          label="Widget"
          value={widgets}
          multiple
          initialValue={[]}
          onApply={setWidgets}
          options={sortOptionsAlphabetically(widgetOptions)}
          renderFormItem={(props) => <FilterSelect {...props} />}
        />
        <ToggleFilter label="Live Cases" value={liveCases} onApply={setLiveCases} />
      </Stack>
      <Button
        variant="outlined"
        startIcon={<Delete />}
        size="small"
        onClick={() => dispatch(resetFilters())}>
        Clear all filters
      </Button>
    </Stack>
  );
};

const mapIdNamePairsToOptions = (idNamePairs: Record<string, string>[]) =>
  idNamePairs.map(({ id, name }): { value: string; label: string } => ({
    value: id,
    label: name,
  }));

const mapNameCodePairsToOptions = (nameCodePairs: DragomanLanguage[]) =>
  nameCodePairs.map(({ code, name }): { value: string; label: string } => ({
    value: code,
    label: `${name} [${code}]`,
  }));

const sortOptionsAlphabetically = (options: Record<string, string>[]) => {
  return options.sort((optionA, optionB) => {
    const labelA = optionA.label.toLowerCase();
    const labelB = optionB.label.toLowerCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
};
