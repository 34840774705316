import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Menu, MenuItem } from '@mui/material';
import { stringify } from 'qs';
import { useState } from 'react';
import { QxMetricsQuery } from '../../types';
import { ExpandMore } from '@mui/icons-material';
import { cleanUpParams } from '../../util';
interface RegionsProps {
  queryParams?: QxMetricsQuery;
}

export function Regions({ queryParams }: RegionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { REACT_APP_BASE_URL_EMEA, REACT_APP_BASE_URL_US, REACT_APP_REGION } = process.env;

  const regionList = [
    { value: REACT_APP_BASE_URL_EMEA, label: 'EMEA' },
    { value: REACT_APP_BASE_URL_US, label: 'US' },
  ];

  const handleNavigate = (region: typeof regionList[0]) => () => {
    const queryString =
      queryParams && stringify(cleanUpParams(queryParams, true), { indices: false });
    window.location.href = `${region.value}?${queryString}`;
    handleClose();
  };

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <Button variant="outlined" size="small" onClick={handleClick} endIcon={<ExpandMore />}>
          {REACT_APP_REGION} Region
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {regionList.map((region) => (
            <MenuItem key={region.value + region.label} onClick={handleNavigate(region)}>
              {region.label} Region
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </div>
  );
}
