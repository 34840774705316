import { stringify } from 'qs';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setFiltersFromURL } from '../state/qxTableSlice';
import { cleanUpParams } from '../util';

export const useQuerySearch = ({
  page,
  panels,
  authoredLanguage,
  usedLocales,
  commitDatesRange,
  sortBy,
  sortDir,
  searchTexts,
  columnsVisible,
  widgets,
  liveCases,
}: Record<string, any>) => {
  const dispatch = useDispatch();

  const queryParameters: Record<string, any> = useMemo(
    () => ({
      page: page.page,
      limit: page.limit,
      panel: panels,
      authored_language: authoredLanguage,
      used_locales: usedLocales,
      commitDatesRange: commitDatesRange,
      sort_by: sortBy,
      sort_dir: sortDir,
      questionnaire_name: searchTexts.questionnaireName,
      committer: searchTexts.committerName,
      columnsVisible,
      widgets,
      liveCases,
    }),
    [
      page.page,
      page.limit,
      panels,
      authoredLanguage,
      usedLocales,
      commitDatesRange,
      sortBy,
      sortDir,
      searchTexts.questionnaireName,
      searchTexts.committerName,
      columnsVisible,
      widgets,
      liveCases,
    ]
  );

  // Read search params from the URL
  useEffect(() => {
    if (window.location.search) {
      dispatch(setFiltersFromURL(window.location.search.substring(1)));
    }
  }, [dispatch]);

  // Write all search params into the URL
  useEffect(() => {
    const cleanedUpParams = cleanUpParams(queryParameters, true);
    window.history.pushState(null, '', window.location.pathname + '?' + stringify(cleanedUpParams));
  }, [queryParameters]);

  return { queryParameters };
};
