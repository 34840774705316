import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { EnhancedTableProps, SortDir } from '../../types';
import styles from './EnhancedTableHead.module.css';
import styled from '@emotion/styled';

const defaultSortDir = SortDir.DESC;

const groupColsName = {
  questionnaire_name: 'questionnaire_details.questionnaire_name',
  committer: 'commit_details.committer',
  commit_datetime: 'commit_details.commit_datetime',
};

export function EnhancedTableHead({
  sortDir,
  sortBy,
  onRequestSort,
  columnGroups,
  stickyColumnStyle,
}: EnhancedTableProps) {
  const columnsVisible = useSelector((state: RootState) => state.qxTable.visibilityColumns);

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const bottomRowTopPostion = { top: columnsVisible.length ? 60 : 0 };

  return (
    <TableHead>
      <TableRow key="1">
        <TableCellTopRow
          key="qxNameHead"
          sx={stickyColumnStyle}
          style={{ height: columnsVisible.length ? 48 : 'auto' }}>
          <TableSortLabel
            active={sortBy === groupColsName.questionnaire_name}
            direction={sortBy === groupColsName.questionnaire_name ? sortDir : defaultSortDir}
            onClick={createSortHandler(groupColsName.questionnaire_name)}>
            Questionnaire Name
          </TableSortLabel>
        </TableCellTopRow>
        <TableCellTopRow key="qxVersionHead">Version</TableCellTopRow>
        <TableCellTopRow key="qxPanelHead">Panel</TableCellTopRow>
        <TableCellTopRow key="qxCommiterHead">
          <TableSortLabel
            active={sortBy === groupColsName.committer}
            direction={sortBy === groupColsName.committer ? sortDir : defaultSortDir}
            onClick={createSortHandler(groupColsName.committer)}>
            Committer
          </TableSortLabel>
        </TableCellTopRow>
        <TableCellTopRow key="qxDatetimeHead">
          <TableSortLabel
            active={sortBy === groupColsName.commit_datetime}
            direction={sortBy === groupColsName.commit_datetime ? sortDir : defaultSortDir}
            onClick={createSortHandler(groupColsName.commit_datetime)}>
            Last Commit (UTC)
          </TableSortLabel>
        </TableCellTopRow>
        <TableCellTopRow key="qxPanelHead">Last Started</TableCellTopRow>
        {columnGroups.map((colGroup: any) => {
          if (columnsVisible.includes(colGroup.id)) {
            const columnKeys = colGroup.columns;
            return (
              <TableCellTopRow
                key={colGroup.id}
                colSpan={columnKeys.length}
                className={styles.tableCellLeftBorder}>
                {colGroup.name}
              </TableCellTopRow>
            );
          }
          return null;
        })}
      </TableRow>
      <TableRow key="2">
        <TableCellBottomRow
          key="qxNameHead-empty"
          sx={stickyColumnStyle}
          style={bottomRowTopPostion}></TableCellBottomRow>
        <TableCellBottomRow
          key="qxPanelHead-empty"
          style={bottomRowTopPostion}></TableCellBottomRow>
        <TableCellBottomRow
          key="qxVersionHead-empty"
          style={bottomRowTopPostion}></TableCellBottomRow>
        <TableCellBottomRow
          key="qxCommiterHead-empty"
          style={bottomRowTopPostion}></TableCellBottomRow>
        <TableCellBottomRow
          key="qxDatetimeHead-empty"
          style={bottomRowTopPostion}></TableCellBottomRow>
        <TableCellBottomRow
          key="qxDatetimeHead-empty"
          style={bottomRowTopPostion}></TableCellBottomRow>
        {columnGroups.flatMap((colGroup: any) => {
          if (columnsVisible.includes(colGroup.id)) {
            const columnKeys = colGroup.columns;
            return columnKeys.map((columnKey: any, index: number) => (
              <TableCellBottomRow
                key={colGroup.id + columnKey.name}
                style={bottomRowTopPostion}
                className={index === 0 ? styles.tableCellLeftBorder : ''}>
                {columnKey.name}
              </TableCellBottomRow>
            ));
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}

const TableCellTopRow = styled(TableCell)(({ sx }: any) => ({
  zIndex: sx && sx.position === 'sticky' ? 4 : 3,
}));

const TableCellBottomRow = styled(TableCell)(({ sx }: any) => ({
  zIndex: sx && sx.position === 'sticky' ? 3 : 2,
}));
