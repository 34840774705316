import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const { REACT_APP_GRYPHON_API_URL } = process.env;

export const gryphonApi = createApi({
  reducerPath: 'gryphonApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_GRYPHON_API_URL,
    credentials: 'include',
  }),
  tagTypes: ['TestSurveyLink'],
  endpoints: (builder) => ({
    getTestLink: builder.mutation({
      query: ({ surveyName }) => ({
        url: `/_ajax/surveys/${surveyName}/test-link/`,
        method: 'GET',
        xhrFields: {
          withCredentials: true,
        },
      }),
    }),
    getISurveyUrl: builder.mutation<
      { message: string; url: string },
      { ivw_url?: string; url: string }
    >({
      query: ({ url }) => ({
        url: `${url}?ivw_redirect=no`,
        method: 'GET',
        xhrFields: {
          withCredentials: true,
        },
      }),
      transformResponse(
        response: any,
        meta: any,
        { ivw_url }: any
      ): { message: string; url: string } {
        const surveyUrl = meta?.response?.headers?.get('Location');
        const url = ivw_url ? surveyUrl.replace(/^https?:\/\/[^/]+\//i, ivw_url) : surveyUrl;

        return { message: response.message, url };
      },
    }),
  }),
});

export const { useGetTestLinkMutation, useGetISurveyUrlMutation } = gryphonApi;
