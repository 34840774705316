import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { parse } from 'qs';
import { Dates, SortDir } from '../types';
import { cleanUpParams } from '../util';

export interface QueryStringQxTable {
  page: string;
  limit: string;
  panel: string[];
  authored_language: string[];
  used_locales: string[];
  commitDatesRange: string;
  sort_by: string;
  sort_dir: string;
  questionnaire_name: string;
  committer: string;
  contentSearch: string;
  columnsVisible: string[];
  widgets: string[];
  liveCases: 0 | 1;
}

export interface QxTableState {
  panels: string[];
  authoredLanguage: string[];
  usedLocales: string[];
  commitDatesRange: string;
  currentlyOpenFilter?: string;
  searchTexts: {
    questionnaireName?: string;
    committerName?: string;
  };
  visibilityColumns: string[];
  page: {
    limit: number;
    page: number;
  };
  sorting: {
    sortBy: string;
    sortDir: SortDir.ASC | SortDir.DESC;
  };
  _setFromURL: boolean;
  widgets: string[];
  liveCases: 0 | 1;
}

const initialState: QxTableState = {
  panels: [],
  authoredLanguage: [],
  usedLocales: [],
  commitDatesRange: Dates.LAST_24H,
  searchTexts: {
    questionnaireName: undefined,
    committerName: undefined,
  },
  visibilityColumns: [],
  page: {
    limit: 25,
    page: 0,
  },
  sorting: {
    sortBy: 'commit_details.commit_datetime',
    sortDir: SortDir.DESC,
  },
  _setFromURL: false,
  widgets: [],
  liveCases: 0,
};

export const qxTableSlice = createSlice({
  name: 'qxTable',
  initialState,
  reducers: {
    setFiltersFromURL: (state, action: PayloadAction<string>) => {
      const {
        page,
        limit,
        panel,
        authored_language,
        used_locales,
        commitDatesRange,
        sort_by,
        sort_dir,
        questionnaire_name,
        committer,
        columnsVisible,
        widgets,
        liveCases,
      } = parse(action.payload) as unknown as QueryStringQxTable;

      // Ignore the change, if it was done once already
      if (state._setFromURL) return state;

      return {
        ...state,
        ...cleanUpParams(
          {
            _setFromURL: true,
            page: {
              page: parseInt(page) || 0,
              limit: parseInt(limit),
            },
            panels: panel,
            authoredLanguage: authored_language,
            usedLocales: used_locales,
            commitDatesRange: commitDatesRange,
            sorting: {
              sortBy: sort_by,
              sortDir: sort_dir,
            },
            searchTexts: {
              questionnaireName: questionnaire_name,
              committerName: committer,
            },
            visibilityColumns: columnsVisible,
            widgets: widgets,
            liveCases: Number(liveCases),
          },
          false
        ),
      };
    },
    setSorting: (
      state,
      action: PayloadAction<{
        sortBy: string;
        sortDir: SortDir;
      }>
    ) => {
      return {
        ...state,
        sorting: {
          sortBy: action.payload.sortBy,
          sortDir: action.payload.sortDir,
        },
        page: { limit: state.page.limit, page: 0 },
      };
    },
    setPanels: (state, action: PayloadAction<typeof state.panels>) => {
      return {
        ...state,
        panels: action.payload,
        page: { limit: state.page.limit, page: 0 },
      };
    },
    setAuthoredLanguage: (state, action: PayloadAction<typeof state.authoredLanguage>) => {
      return {
        ...state,
        authoredLanguage: action.payload,
        page: { limit: state.page.limit, page: 0 },
      };
    },
    setUsedLocales: (state, action: PayloadAction<typeof state.usedLocales>) => {
      return {
        ...state,
        usedLocales: action.payload,
        page: { limit: state.page.limit, page: 0 },
      };
    },
    setCommitDates: (state, action: PayloadAction<typeof state.commitDatesRange>) => {
      return {
        ...state,
        commitDatesRange: action.payload[0].toString(),
        liveCases: action.payload[0].toString() ? 0 : state.liveCases,
        page: { limit: state.page.limit, page: 0 },
      };
    },
    setLiveCases: (state, action: PayloadAction<typeof state.liveCases>) => {
      return {
        ...state,
        liveCases: action.payload,
        commitDatesRange: '',
        page: { limit: state.page.limit, page: 0 },
      };
    },

    setWidgets: (state, action: PayloadAction<typeof state.widgets>) => {
      return {
        ...state,
        widgets: action.payload,
        page: { limit: state.page.limit, page: 0 },
      };
    },
    resetFilters: (state) => {
      return {
        ...state,
        panels: [],
        authoredLanguage: [],
        usedLocales: [],
        commitDatesRange: '',
        page: { limit: state.page.limit, page: 0 },
        widgets: [],
        liveCases: 0,
      };
    },
    setFilterOpen: (state, action: PayloadAction<typeof state.currentlyOpenFilter>) => {
      return {
        ...state,
        currentlyOpenFilter: action.payload,
      };
    },
    closeFilters: (state) => ({
      ...state,
      currentlyOpenFilter: undefined,
    }),

    setSearchTexts: (
      state,
      action: PayloadAction<{
        questionnaireName?: string;
        committerName?: string;
        content?: string;
      }>
    ) => {
      return {
        ...state,
        searchTexts: {
          questionnaireName: action.payload.questionnaireName,
          committerName: action.payload.committerName,
          content: action.payload.content,
        },
        page: { limit: state.page.limit, page: 0 },
      };
    },

    setVisibilityColumns: (state, action: PayloadAction<typeof state.visibilityColumns>) => {
      return {
        ...state,
        visibilityColumns: action.payload,
      };
    },

    setPage: (
      state,
      action: PayloadAction<{
        limit: number;
        page: number;
      }>
    ) => {
      return {
        ...state,
        page: {
          limit: action.payload.limit,
          page: action.payload.page,
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSorting,
  setPanels,
  setAuthoredLanguage,
  setUsedLocales,
  setCommitDates,
  resetFilters,
  setFilterOpen,
  closeFilters,
  setSearchTexts,
  setVisibilityColumns,
  setPage,
  setFiltersFromURL,
  setWidgets,
  setLiveCases,
} = qxTableSlice.actions;

export default qxTableSlice.reducer;
