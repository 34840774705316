import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { setVisibilityColumns } from '../../state/qxTableSlice';
import Settings from '@mui/icons-material/Settings';
import { FilterSelect } from '../../components/FilterSelect/FilterSelect.index';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface ColumnsProps {
  columnsVisible: string[];
}

const columnsList = [
  { value: 'questionnaire_details', label: 'Questionnaire Content' },
  { value: 'interlude_content', label: 'Interlude Content' },
  { value: 'pdl_content', label: 'PDL Content' },
  { value: 'exit_counts', label: 'Exit Counts' },
  { value: 'gotos', label: 'Gotos' },
  { value: 'responses_activity', label: 'Response Options' },
  { value: 'subquestions', label: 'Subquestions' },
  { value: 'guards', label: 'Guards' },
  { value: 'languages', label: 'Languages' },
];

export const Columns = ({ columnsVisible }: ColumnsProps) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleColumnSelection = (selections: string[]) => {
    dispatch(setVisibilityColumns(selections));
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ position: 'relative' }}>
            <Button
              variant="outlined"
              startIcon={<Settings />}
              size="small"
              onClick={() => setOpen(!open)}>
              Columns
            </Button>
            {open && (
              <FloatingPaper>
                <FilterSelect
                  selected={columnsVisible}
                  setSelected={handleColumnSelection}
                  options={columnsList}
                  multiple
                />
              </FloatingPaper>
            )}
          </div>
        </ClickAwayListener>
      </Stack>
    </div>
  );
};

const FloatingPaper = styled(Paper)`
  position: absolute;
  width: 220px;
  z-index: 500;
  right: 0;
  top: 110%;
  border: 1px solid #5048e580;
  overflow-x: hidden;
`;
